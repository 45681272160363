import React from 'react'
import { Typography } from '@material-ui/core'

function Contact() {
  return (
    <>
      <Typography variant="body1">Edit Contact component or pages/contact.tsx to include your contact information.</Typography>
    </>
  )
}

export default Contact
